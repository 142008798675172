// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@charset \"UTF-8\"\n/**\n * @description 全局主题变量配置\n */", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu-color": "hsla(0, 0%, 100%, 0.95)",
	"menu-color-active": "hsla(0, 0%, 100%, 0.95)",
	"menu-background": "#282c34",
	"column-second-menu-background": "#fff",
	"vab-border-radius": "2.5px",
	"vab-color-grey": "rgba(0, 0, 0, 0.65)",
	"vab-color-black": "#515a6e",
	"vab-color-primary": "#6954f0",
	"vab-color-primary-light-1": "#7865f2",
	"vab-color-primary-light-2": "#8776f3",
	"vab-color-primary-light-3": "#9687f5",
	"vab-color-primary-light-4": "#a598f6",
	"vab-color-primary-light-5": "#b4aaf8",
	"vab-color-primary-light-6": "#c3bbf9",
	"vab-color-primary-light-7": "#d2ccfb",
	"vab-color-primary-light-8": "#e1ddfc",
	"vab-color-primary-light-9": "#f0eefe",
	"vab-color-success": "#13ce66",
	"vab-color-success-light": "#d0f5e0",
	"vab-color-success-lighter": "#e7faf0",
	"vab-color-warning": "#ffba00",
	"vab-color-warning-light": "#fff1cc",
	"vab-color-warning-lighter": "#fff8e6",
	"vab-color-danger": "#ff4d4f",
	"vab-color-danger-light": "#ffdbdc",
	"vab-color-danger-lighter": "#ffeded",
	"vab-color-error": "#ff4d4f",
	"vab-color-error-light": "#ffdbdc",
	"vab-color-error-lighter": "#ffeded",
	"vab-color-info": "#909399",
	"vab-color-info-light": "#e9e9eb",
	"vab-color-info-lighter": "#f4f4f5",
	"vab-border-radius-base": "2.5px",
	"vab-color-transition": "#1890ff",
	"vab-left-menu-width": "266px"
};
export default ___CSS_LOADER_EXPORT___;
