// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@charset \"UTF-8\"\n/**\n * @description 全局主题变量配置\n */", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu-color": "hsla(0, 0%, 100%, 0.95)",
	"menu-color-active": "hsla(0, 0%, 100%, 0.95)",
	"menu-background": "#282c34",
	"column-second-menu-background": "#fff",
	"vab-border-radius": "2.5px"
};
export default ___CSS_LOADER_EXPORT___;
