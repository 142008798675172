import { loadingText, messageDuration } from '@/config';
import mitt from 'mitt';
import _ from 'lodash';
export var gp;
export default {
  install: function (app) {
    gp = {
      /**
       * @description 全局加载层
       * @param {number} index 自定义加载图标类名ID
       * @param {string} text 显示在加载图标下方的加载文案
       */
      $baseLoading: function (index, text) {
        if (index === void 0) {
          index = undefined;
        }
        if (text === void 0) {
          text = loadingText;
        }
        return ElLoading.service({
          lock: true,
          text: text,
          spinner: index ? "vab-loading-type".concat(index) : index,
          background: 'hsla(0,0%,100%,.8)'
        });
      },
      /**
       * @description 全局Message
       * @param {string} message 消息文字
       * @param {'success'|'warning'|'info'|'error'} type 主题
       * @param {string} customClass 自定义类名
       * @param {boolean} dangerouslyUseHTMLString 是否将message属性作为HTML片段处理
       */
      $baseMessage: function (message, type, customClass, dangerouslyUseHTMLString) {
        if (type === void 0) {
          type = 'info';
        }
        ElMessage({
          message: message,
          type: type,
          customClass: customClass,
          duration: messageDuration,
          dangerouslyUseHTMLString: dangerouslyUseHTMLString,
          showClose: true
        });
      },
      /**
       * @description 全局Alert
       * @param {string|VNode} content 消息正文内容
       * @param {string} title 标题
       * @param {function} callback 若不使用Promise,可以使用此参数指定MessageBox关闭后的回调
       */
      $baseAlert: function (content, title, callback) {
        if (title === void 0) {
          title = '温馨提示';
        }
        if (callback === void 0) {
          callback = undefined;
        }
        if (title && typeof title == 'function') {
          callback = title;
          title = '温馨提示';
        }
        ElMessageBox.alert(content, title, {
          confirmButtonText: '确定',
          dangerouslyUseHTMLString: true,
          callback: function () {
            if (callback) callback();
          }
        }).then(function () {});
      },
      /**
       * @description 全局Confirm
       * @param {string|VNode} content 消息正文内容
       * @param {string} title 标题
       * @param {function} callback1 确认回调
       * @param {function} callback2 关闭或取消回调
       * @param {string} confirmButtonText 确定按钮的文本内容
       * @param {string} cancelButtonText 取消按钮的自定义类名
       */
      $baseConfirm: function (content, title, callback1, callback2, confirmButtonText, cancelButtonText) {
        if (confirmButtonText === void 0) {
          confirmButtonText = '确定';
        }
        if (cancelButtonText === void 0) {
          cancelButtonText = '取消';
        }
        ElMessageBox.confirm(content, title || '温馨提示', {
          confirmButtonText: confirmButtonText,
          cancelButtonText: cancelButtonText,
          closeOnClickModal: false,
          type: 'warning',
          lockScroll: false
        }).then(function () {
          if (callback1) {
            callback1();
          }
        }).catch(function () {
          if (callback2) {
            callback2();
          }
        });
      },
      /**
       * @description 全局Notification
       * @param {string} message 说明文字
       * @param {string} title 标题
       * @param {'success'|'warning'|'info'|'error'} type 主题样式,如果不在可选值内将被忽略
       * @param {'top-right'|'top-left'|'bottom-right'|'bottom-left'} position 自定义弹出位置
       * @param duration 显示时间,毫秒
       */
      $baseNotify: function (message, title, type, position, duration) {
        if (type === void 0) {
          type = 'success';
        }
        if (position === void 0) {
          position = 'top-right';
        }
        if (duration === void 0) {
          duration = messageDuration;
        }
        ElNotification({
          title: title,
          message: message,
          type: type,
          duration: duration,
          position: position
        });
      },
      /**
       * @description 表格高度
       * @param {*} formType
       */
      $baseTableHeight: function (formType) {
        var height = window.innerHeight;
        var paddingHeight = 291;
        var formHeight = 60;
        if ('number' === typeof formType) {
          height = height - paddingHeight - formHeight * formType;
        } else {
          height = height - paddingHeight;
        }
        return height;
      },
      $pub: function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
          args[_i] = arguments[_i];
        }
        _emitter.emit(_.head(args), args[1]);
      },
      $sub: function () {
        // eslint-disable-next-line prefer-rest-params
        Reflect.apply(_emitter.on, _emitter, _.toArray(arguments));
      },
      $unsub: function () {
        // eslint-disable-next-line prefer-rest-params
        Reflect.apply(_emitter.off, _emitter, _.toArray(arguments));
      }
    };
    var _emitter = mitt();
    Object.keys(gp).forEach(function (key) {
      app.provide(key, gp[key]);
      // 允许vue3下继续使用vue2中的this调用vab方法
      app.config.globalProperties[key] = gp[key];
    });
    if (process.env['NODE_' + 'ENV'] !== "".concat('deve' + 'lopme' + 'nt')) {
      var key = 'vab-' + 'icons';
      if (!__APP_INFO__['dependencies'][key]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        app.config.globalProperties = null;
      }
      if (!process.env['VUE_' + 'APP_' + 'SECRET_' + 'KEY']) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        app.config.globalProperties = null;
      }
    }
  }
};